<template>
  <div flex>
    <NFormGroup
      label="Email"
      hint="Optional"
    >
      <NInput
        placeholder="phojrengel@gmail.com"
        leading="i-heroicons-envelope-20-solid"
      />
    </NFormGroup>
  </div>
</template>
