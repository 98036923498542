<template>
  <div grid="~ sm:cols-2" gap-4>
    <NInput
      input="outline"
      placeholder="This is the outline variant (default color)"
    />

    <NInput
      input="solid"
      placeholder="This is the solid variant (default color)"
    />

    <NInput
      input="~"
      placeholder="This is the base input"
    />
  </div>
</template>
