<template>
  <div flex>
    <NInput
      leading="i-heroicons-currency-dollar-20-solid"
      placeholder="Search"
      class="pr-12"
    >
      <template #trailing>
        <span class="text-sm">
          USD
        </span>
      </template>
    </NInput>
  </div>
</template>
