<template>
  <div flex gap-4>
    <NButton
      label="Normal"
    />

    <NButton
      btn="block"
      label="Block"
    />
  </div>
</template>
