<template>
  <div flex="~ col" space-y-4>
    <NAlert
      title="Customize icon"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
      icon="i-heroicons-beaker-20-solid"
    />

    <NAlert
      alert="outline-info"
      title="Info icon"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
      icon
    />

    <NAlert
      alert="outline-success"
      title="Success icon"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
      icon
    />

    <NAlert
      alert="outline-warning"
      title="Warning icon"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
      icon
    />

    <NAlert
      alert="outline-error"
      title="Error icon"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
      icon
    />
  </div>
</template>
