<template>
  <div flex="~ items-center" gap-4>
    <NIndicator indicator="solid-success">
      <NAvatar src="/images/avatar.png" alt="Phojie Rengel" />
    </NIndicator>

    <NIndicator size="2xl" indicator="solid-info">
      <NAvatar src="/images/avatar.png" alt="Phojie Rengel" />
    </NIndicator>
  </div>
</template>
