<template>
  <div flex="~ wrap" gap-4>
    <NKbd label="⌘" />

    <NKbd label="K" />

    <NKbd label="Fn" />

    <NKbd label="Ctrl" />
  </div>
</template>
