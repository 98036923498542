<template>
  <div flex="~ wrap" gap-4>
    <NButton
      label="Button Solid"
      btn="solid"
    />

    <NButton
      label="Button Outline"
      btn="outline"
    />

    <NButton
      label="Button Soft"
      btn="soft"
    />

    <NButton
      label="Button Ghost"
      btn="ghost"
    />

    <NButton
      label="Button Link"
      btn="link"
    />

    <NButton
      label="Button text"
      btn="text"
    />

    <NButton
      label="Button Base"
      btn="~"
    />
  </div>
</template>
