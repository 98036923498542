<template>
  <div flex="~ wrap" items-center gap-4>
    <NSwitch
      size="xs"
    />

    <NSwitch
      :model-value="true"
      switch="xl"
    />

    <NSwitch
      :model-value="true"
      size="3vw"
      outset
    />

    <NSwitch
      size="1cm"
    />

    <NSwitch
      size="45px"
      outset
    />
  </div>
</template>
