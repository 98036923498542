<template>
  <div flex flex-wrap gap-2>
    <NBadge badge="soft" label="Badge" closable />

    <NBadge badge="solid" label="Badge" closable />

    <NBadge badge="outline" label="Badge" closable />

    <NBadge badge="soft-gray" label="Badge" closable />

    <NBadge badge="solid-black" label="Badge" closable />

    <NBadge badge="outline-white" label="Badge" closable />
  </div>
</template>
