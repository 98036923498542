<template>
  <div flex="~ wrap items-center" gap-4 size="2xl">
    <NIcon name="i-info" text-info />

    <NIcon name="i-success" text-success />

    <NIcon name="i-warning" text-warning />

    <NIcon name="i-error" text-error />

    <NIcon name="i-loading" animate-spin />

    <NIcon name="i-close" />
  </div>
</template>
