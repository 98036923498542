<template>
  <div flex="~ items-center" gap-4>
    <NAvatar avatar="solid" label="PR" />

    <NAvatar avatar="soft" label="PR" />

    <NAvatar avatar="outline" label="PR" />

    <NAvatar avatar="~" label="PR" />
  </div>
</template>
