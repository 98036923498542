<template>
  <div flex="~ col" space-y-4>
    <NAlert
      alert="outline"
      title="Outline variant (default)"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
    />

    <NAlert
      alert="soft"
      title="Soft variant"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
    />

    <NAlert
      alert="border"
      title="Border variant"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
    />
  </div>
</template>
