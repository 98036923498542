<template>
  <div flex="~ items-center" gap-4>
    <NIndicator indicator="solid-gray">
      <NAvatar src="/images/avatar.png" />
    </NIndicator>

    <NIndicator indicator="solid-lime">
      <NAvatar label="PR" />
    </NIndicator>

    <NIndicator indicator="solid-indigo">
      <NAvatar />
    </NIndicator>
  </div>
</template>
