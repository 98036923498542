<template>
  <div flex="~ col" gap-4>
    <NCard
      title="Outline variant"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
      card="outline"
    />
    <NCard
      title="Soft variant"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
      card="soft"
    />
    <NCard
      title="Base variant"
      description="Lorem ipsum dolor sit amet consectetur adipisicing elit. aliquid pariatur, ipsum similique veniam quo totam eius aperiam dolorum."
      card="~"
    />
  </div>
</template>
