<template>
  <div flex flex-wrap gap-2>
    <NBadge badge="soft" label="Soft Badge" />

    <NBadge badge="solid" label="Solid Badge" />

    <NBadge badge="outline" label="Outline Badge" />

    <NBadge badge="~" label="Base Badge" />
  </div>
</template>
