<template>
  <div flex="~ wrap" gap-4>
    <NButton
      disabled
      label="Disabled"
    />

    <NButton
      btn="outline"
      disabled
      label="Disabled"
    />

    <NButton
      btn="link"
      disabled
      label="Disabled"
    />

    <NButton
      btn="ghost"
      disabled
      label="Disabled"
    />

    <NButton
      btn="soft"
      disabled
      label="Disabled"
    />

    <NButton
      btn="text"
      disabled
      label="Disabled"
    />

    <NButton
      btn="solid-black"
      disabled
      label="Disabled"
    />

    <NButton
      btn="solid-white"
      disabled
      label="Disabled"
    />
  </div>
</template>
