<template>
  <div grid="~ sm:cols-2" gap-4>
    <NInput
      disabled
      placeholder="You can't click here (disabled)"
    />

    <NInput
      readonly
      placeholder="You can't type here (readonly)"
    />
  </div>
</template>
