<template>
  <div flex="~ items-center" gap-4>
    <NAvatar avatar="solid-primary" class="rounded-md">
      <span class="text-white font-extrabold">PR</span>
    </NAvatar>

    <NAvatar avatar="soft-primary">
      <NIcon name="i-heroicons-envelope-20-solid" />
    </NAvatar>
  </div>
</template>
